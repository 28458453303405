import { useEffect, useState } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import { toast } from 'react-hot-toast';
import Button from '../components/Button';
import Text from '../components/Text';
import { IS_TEST } from '../constants';

const Input = styled.textarea`
  border: none;
  width: 100%;
  min-height: 16.7rem;
  padding: 1rem;
  border: 1px solid #222529;
  border-radius: 0.4rem;
  margin-top: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2.1rem;
  background: none;
  letter-spacing: 0.15px;
  color: #222529;

  &::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2.1rem;
    letter-spacing: 0.15px;
    color: #888d96;
  }

  &:focus {
    outline: none;
  }
`;

const InputWorryPage = ({ value, onChange, onReady }) => {
  const [submitStarted, setSubmitStarted] = useState(false);
  const [registered, setRegistsered] = useState(false);

  const utmParams = new URLSearchParams(window.location.search);
  const URL = IS_TEST
    ? 'https://test.tarosister.com/api/v1/user/worry-again'
    : `https://api.tarosister.com/api/v1/user/worry-again`;

  const userRegisterHandler = async () => {
    if (!utmParams.has('phone')) {
      toast.error('등록 중 오류가 발생했습니다.');
      return;
    }

    const phone = utmParams.get('phone');
    if (submitStarted) return;
    if (registered) return;

    setSubmitStarted(prev => true);

    try {
      const response = await fetch(URL, {
        method: 'POST',
        body: JSON.stringify({
          phone,
          worry: value,
          type: 1,
        }),
        headers: {
          'Content-type': 'application/json',
        },
      });

      if (response.status === 400 && !response.ok) {
        amplitude.getInstance().logEvent('already_registered', {
          phone,
          referral: 'worry_again',
        });
        setTimeout(() => {
          window.open(`https://tarot-sister.com/${window.location.search}`, '_self');
        }, 2000);
      } else if (response.status !== 400 && !response.ok) {
        toast.error('대상 고객이 아닙니다.');
        throw new Error(`${response.status} error`);
      } else {
        const identify = new amplitude.Identify().setOnce('phone', phone);
        amplitude.getInstance().identify(identify);
        onReady();
        setRegistsered(prev => true);
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitStarted(prev => false);
  };

  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    amplitude.getInstance().logEvent('view_main', { referral: 'worry_again' });
  }, []);

  return (
    <>
      <Text style={{ width: '100%', margin: '1.6rem 0' }}>
        지금의 고민을 이야기해주세요.
        <br />
        {value.length === 0 && <span style={{ color: '#E1354C' }}>30자 이상 부탁드려요</span>}
        {value.length <= 30 && value.length !== 0 && value.length !== 30 && (
          <span style={{ color: '#E1354C' }}>{`${30 - value.length}자 더 부탁드려요`}</span>
        )}
      </Text>
      <Input placeholder={''} value={value} onChange={onChange} />
      <div
        style={{
          marginTop: '1.6rem',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
        <Button
          text="입력 완료"
          width="50%"
          color="#fff"
          backgroundColor={value.length >= 30 ? '#222529' : '#CFD4DA'}
          style={{
            fontFamily: 'Roboto',
            fontSize: '2rem',
            fontWeight: 700,
            lineHeight: '6.4rem',
          }}
          onClickHandler={
            value.length >= 30
              ? userRegisterHandler
              : () => {
                  toast.error('고민을 30자 이상 입력해주세요.');
                }
          }
        />
      </div>
    </>
  );
};

export default InputWorryPage;
