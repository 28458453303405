import { useEffect } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import Text from '../components/Text';

const Ready = () => {
  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    amplitude.getInstance().logEvent('registration', { referral: 'worry_again' });
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <SubText style={{ marginTop: '1.6rem', fontWeight: 700, fontSize: '2.5rem' }}>
        [<span style={{ color: '#E1354C' }}> 02-6953-6510 </span>]
      </SubText>
      <Text style={{ textAlign: 'center', marginTop: '1.6rem' }}>
        곧 위 번호로 <br />
        상담 전화가 올 거에요.
        <br />
        <br />
        전화를 받지 않으면
        <br />
        상담사님의
        <br />
        소중한 시간이 낭비되니
        <br />꼭 받아주세요.
      </Text>
    </div>
  );
};

const SubText = styled.p`
  font-family: 'Inter';
  margin-top: 1.6rem;
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 180%;
  text-align: center;
  color: #2e1717;
`;

export default Ready;
