import styled from 'styled-components';
import './loadAmplitude';
import { useState, useEffect } from 'react';
import amplitude from 'amplitude-js';
import Header from './components/Header';
import devider from './assets/border.png';
import './App.css';
import InputWorryPage from './pages/InputWorryPage';
import Ready from './pages/Ready';

const Devider = styled.img`
  width: 32rem;
  height: 1.07rem;
`;

function App() {
  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });
  }, []);
  const [pageNum, setPageNum] = useState(0);
  const buttonClickHandler = () => {
    setPageNum(prev => prev + 1);
  };

  const [inputWorry, setInputWorry] = useState('');

  const inputWorryChangeHandler = event => {
    setInputWorry(prev => event.target.value);
  };

  return (
    <>
      <Header />
      <div
        style={{
          position: 'relative',
          padding: '0 2rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Devider src={devider} />

        {pageNum === 0 && (
          <InputWorryPage value={inputWorry} onChange={inputWorryChangeHandler} onReady={buttonClickHandler} />
        )}
        {pageNum === 1 && <Ready />}
      </div>
    </>
  );
}

export default App;
